import { RESULT_ROWS_COUNT_LIMIT } from '@/assets/js/const';

/**
 * 確認ダイアログ
 */
// 未保存実行
export const CONFIRM_MESSAGE_UNSAVED_DATA = '未保存のデータがあります。\n保存しますか？\n\n※別タブや複数ユーザーで同時に同じデータを操作した場合、\n　入力内容が正しく反映されない場合があります。';
// 入力データ保存
export const CONFIRM_MESSAGE_SAVE_DATA = '変更内容を保存します。\nよろしいですか？\n\n※別タブや複数ユーザーで同時に同じデータを操作した場合、\n　入力内容が正しく反映されない場合があります。';
// 削除
export const CONFIRM_MESSAGE_DELETE_DATA = 'レコードを削除します。\nよろしいですか？\n\n※別タブや複数ユーザーで同時に同じデータを操作した場合、\n　入力内容が正しく反映されない場合があります。';
// 連続登録
export const CONFIRM_MESSAGE_CLEAR_DATA = '続いて登録を行いますか？';
// メール送信
export const CONFIRM_MESSAGE_SEND_EMAIL = 'メールを送信します。\nよろしいですか？';
// CSV出力取り消し
export const CONFIRM_MESSAGE_CSV_REMOVE_DATA = 'このレコードをCSV出力の対象から外します。\nよろしいですか？\n※注文データ自体は削除されません。';
// CSV出力
export const CONFIRM_MESSAGE_CSV_INSERT_DATA = 'CSVファイルを作成し、販売管理システムに配置します。\nよろしいですか？';
// 管理者登録
export const CONFIRM_MESSAGE_REGISTER_AUTH_USER = '管理者ユーザーとして新規登録します。\nよろしいですか？';
// 権限区分の更新
export const CONFIRM_MESSAGE_UPGRADE_AUTH_USER = '編集中の一般ユーザーを管理者ユーザーにします。\nよろしいですか？';
// CSV出力時お客様卸価格チェック
export const CONFIRM_MESSAGE_ZERO_CUSTOMER_PRICE = 'お客様卸価格が0の明細があります。\nこのまま計上しますか？';
// メールフォーム破棄
export const CONFIRM_MESSAGE_LEAVE_EMAIL_FORM = '入力内容を破棄し、メール編集画面を閉じます。\nよろしいですか？';
// 単価登録
export const CONFIRM_MESSAGE_INSERT_UNIT_PRICE = '選択したレコードの単価登録をします。\nよろしいですか？';
// 原価更新依頼
export const CONFIRM_MESSAGE_UPDATE_REQUEST_REJECT = '選択した商品の原価変更依頼を却下します。\nよろしいですか？';
// PDF出力
export const CONFIRM_MESSAGE_OUTPUT_PDF = '確定してPDFを出力します。\nよろしいですか？';
// PDF出力（再発行）
export const CONFIRM_MESSAGE_RE_OUTPUT_PDF = 'PDFを出力します。\nよろしいですか？';
// 発注データ削除
export const CONFIRM_MESSAGE_DELETE_ORDER = '発注データを削除します。\nよろしいですか？';

/**
 * 通知ダイアログ
 */
// 入力データ未保存
export const ALERT_UNSAVED_DATA = '入力データが保存されていません。';
// 入力データ変更なし
export const ALERT_ALREADY_SAVED_DATA = '入力されたデータはすでに登録されています。';
// 登録完了
export const ALERT_COMPLETE_CRUD_DATA = '登録が完了しました。';
// 登録完了（発注簿・東北C）
export const ALERT_COMPLETE_INSERT_DATA = '登録が完了しました。\n帳票を作成の上、FAX送信してください。';
// 削除完了
export const ALERT_COMPLETE_DELETE_ORDER = '削除が完了しました。';
// レコード未選択
export const ALERT_UNSELECTED_DATA = 'レコードを選択してください。';
// 登録用明細データなし
export const ALERT_SAVE_ZERO_DATA = '明細データが1件も登録されていません。';
// 検索データ0件
export const ALERT_SEARCH_ZERO_DATA = '検索結果がありません。';
// コピー完了
export const ALERT_COPY_COMPLETE = 'コピーしました。';
// 担当者データなし
export const ALERT_NO_TANTOSHA_DATA = '担当者の情報が取得できません。';
// CSV出力対象なし
export const ALERT_NO_CSV_DATA = 'CSV出力できるレコードがありません。\n仕入先伝票番号、仕入CSVの項目を確認してください。';
// サン電子商品修正時
export const ALERT_SUNELE_EDIT = 'サン電子商品のため修正できません。';
// 出力完了
export const ALERT_OUTPUT_COMPLETE = '出力が完了しました。';
// 最大件数取得時
export const ALERT_ROWS_COUNT_LIMIT = `検索結果が表示最大件数を超えています。\n検索結果のうち最新の${RESULT_ROWS_COUNT_LIMIT.toLocaleString()}件が取得されます。`;
// 最大件数取得時(検索で絞り込む)
export const ALERT_TOO_MANY_ROWS_COUNT = `検索結果が多いため、処理能力を超過します。\n検索条件を追加して、対象を絞り込んでください。`;
// 帳票：発注書・見積書（備考欄最大文字数超過）
export const ALERT_TOO_LONG_SUMMARY = `備考欄が表示最大長を超えているデータが存在します。\n最大文字数を超過した部分は出力されません。`;
// 帳票：見積書（コメント欄最大文字数超過）
export const ALERT_TOO_LONG_COMMENT = `コメント欄が表示最大長を超えているデータが存在します。\n最大文字数を超過した部分は出力されません。`;

// 発注データ削除NG
export const ALERT_DELETE_NOT_ALLOWED = {
  URIAGE_CSV_FLG: '売上CSV出力済の明細が存在するため削除できません',
  SHIIRE_CSV_FLG: '仕入CSV出力済の明細が存在するため削除できません',
  EMAIL_SENT_FLG: 'メール送信済の明細が存在するため削除できません',
  KARIDEN_INSATSU_FLG: '仮伝印刷済の明細が存在するため削除できません',
  IRAI_FLG: '返品依頼済の明細が存在するため削除できません',
  HENKYAKU_FLG: '返品済の明細が存在するため削除できません',
  TOSHIN_DENPYO_INSATSU_FLG: 'トシン伝票印刷済の明細が存在するため削除できません',
}


/**
 * エラーダイアログ
 */
// エラー
export const ALERT_MESSAGE_COMMON_ERROR = 'エラーが発生しました。\nしばらくしてから、もう一度お試しください。';
// Eメール認証エラー
export const ALERT_MESSAGE_EMAIL_AUTH_ERROR = 'メールアドレスが認証されていません。\n管理者に問い合わせてください。';

/**
 * モーダル
 */
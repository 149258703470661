<template>
  <div class="container">
    <!-- 初期設定画面 -->
    <v-container>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>初期設定</v-card-title>
            <v-card-text>
              <v-form ref="form">
                <v-row>
                  <!-- 部門コード -->
                  <v-col cols="12" sm="12">
                    <v-text-field
                      v-model="currentParams.departmentCode"
                      label="部門コード"
                      :rules="[required]"
                      persistent-hint
                      return-object
                      disabled
                      tabindex="1"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 部門名 -->
                  <v-col cols="12" sm="12">
                    <v-text-field
                      v-model="currentParams.departmentName"
                      label="部門名"
                      :rules="[required, limit_length50]"
                      counter="50"
                      clearable
                      tabindex="2"
                      autofocus
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 郵便番号 -->
                  <v-col cols="12" sm="12">
                    <v-text-field
                      v-model="currentParams.postalCode"
                      label="郵便番号"
                      :rules="[required, regex_number, limit_length10]"
                      counter="10"
                      clearable
                      tabindex="3"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 住所 -->
                  <v-col cols="12" sm="12">
                    <v-text-field
                      v-model="currentParams.address"
                      label="住所"
                      :rules="[required, limit_length50]"
                      counter="50"
                      clearable
                      tabindex="4"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- TEL -->
                  <v-col cols="12" sm="12">
                    <v-text-field
                      v-model="currentParams.telNo"
                      label="TEL"
                      :rules="[required, regex_number, limit_length20]"
                      counter="20"
                      clearable
                      tabindex="5"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- FAX -->
                  <v-col cols="12" sm="12">
                    <v-text-field
                      v-model="currentParams.faxNo"
                      label="FAX"
                      :rules="[required, regex_number, limit_length20]"
                      counter="20"
                      clearable
                      tabindex="6"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 発注番号 -->
                  <v-col cols="12" sm="12">
                    <v-text-field
                      v-model="currentParams.departmentOrderNo"
                      label="発注番号"
                      :rules="[required, number, limit_length10]"
                      counter="10"
                      clearable
                      hide-spin-buttons
                      type="number"
                      tabindex="7"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- トシン掛率 -->
                  <v-col cols="12" sm="12">
                    <v-text-field
                      v-model="currentParams.toshinRate"
                      label="トシン掛率"
                      :rules="[required, toshin_rate, limit_length9]"
                      counter="9"
                      clearable
                      :hint="
                        `見積システムで使用するため、その他システムでは変更しないでください。`
                      "
                      persistent-hint
                      hide-spin-buttons
                      type="number"
                      tabindex="8"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 承認者メールアドレス -->
                  <v-col cols="12" sm="12">
                    <v-text-field
                      v-model="currentParams.authorizerMail"
                      label="承認者メールアドレス"
                      :rules="[required, regex_email, limit_length254]"
                      counter="254"
                      clearable
                      :hint="
                        `商品原価更新を依頼する際、指定の宛先に依頼メールを送信します。`
                      "
                      persistent-hint
                      tabindex="9"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 更新日付 -->
                  <v-col cols="12" sm="12">
                    <v-text-field
                      v-model="currentParams.updatedDate"
                      label="更新日付"
                      :hint="`登録すると自動設定されます`"
                      persistent-hint
                      disabled
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-divider class="mt-5"></v-divider>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-center">
              <!-- 更新 -->
              <v-btn
                color="secondary"
                v-on:click="onClickUpdate"
                rounded
                class="my-2"
                tabindex="10"
              >
                <v-icon left> mdi-database-edit </v-icon>更新
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// graphqlのインポート
import { API, graphqlOperation } from "aws-amplify";
import { listM_SHOKISETTEI } from "@/graphql/queries";
import { executeTransactSql } from "@/graphql/mutations";
import { CONFIRM_MESSAGE_UNSAVED_DATA, ALERT_MESSAGE_COMMON_ERROR, ALERT_ALREADY_SAVED_DATA, ALERT_COMPLETE_CRUD_DATA } from "@/assets/js/dialog_messages";
import { addLog } from '@/assets/js/logger';
import MSG from '@/assets/js/messages';
import TYPE from '@/assets/js/type';
export default {
  name: "Settings",

  data: () => ({
    // 変数定義
    currentParams: {
      // 部門コード
      departmentCode: 0,
      // 部門名
      departmentName: "",
      // 郵便番号
      postalCode: "",
      // 住所
      address: "",
      // TEL
      telNo: "",
      // FAX
      faxNo: "",
      // 発注番号
      departmentOrderNo: "",
      // トシン掛率
      toshinRate: 0,
      // 承認者メールアドレス
      authorizerMail: "",
      // 更新日付
      updatedDate: "",
    },
    // 未保存チェック用
    originalParams: {},

    // ログインユーザーコード
    loginUserCode: "",
    // ログインユーザー所属部門
    loginUserDepartmetCode: null,

    // 入力規則
    // 入力必須の制約
    required: (value) => !!value || "必ず入力してください",
    // 番号の制約
    regex_number: (value) =>
      /^[0-9-]+$/.test(value) || "数値とハイフンで入力してください",
    // 数値の制約
    number: (value) => /^[0-9.]+$/.test(value) || "数値で入力してください",
    // トシン掛率の制約
    toshin_rate: (value) =>
      /^([1-9][0-9]{0,2}|0)(\.[0-9]{1,5})?$/.test(value) ||
      "整数値3桁、小数値5桁で入力してください",
    // E-mailの正規表現
    regex_email: (value) =>
      !value ||
      /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      ) ||
      "E-mailの形式で入力してください",
    // 文字数の制約 最大10文字
    limit_length10: (value) =>
      value == null || value.length <= 10 || "10文字以内で入力してください",
    // 文字数の制約 最大9文字
    limit_length9: (value) =>
      String(value) == null ||
      String(value).length <= 9 ||
      "9文字以内で入力してください",
    // 文字数の制約 最大20文字
    limit_length20: (value) =>
      value == null || value.length <= 20 || "20文字以内で入力してください",
    // 文字数の制約 最大50文字
    limit_length50: (value) =>
      value == null || value.length <= 50 || "50文字以内で入力してください",
    // 文字数の制約 最大254文字
    limit_length254: (value) =>
      value == null || value.length <= 254 || "254文字以内で入力してください",
  }),
  /**
   * created ライフサイクルフック
   */
  created: async function() {
    // メソッド名を定義
    const method_name="function"
    // オペレーションタイプを定義
    const operation_type="0006"

    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

    // ローディング画面を表示
    this.$store.commit("setLoading", true);

    // ログイン中のユーザー情報を取得
    if (!(await this.getLoginUserInfo())) {
      alert(ALERT_MESSAGE_COMMON_ERROR);
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    }

    // 初期化処理の実行
    if (!(await this.initialize())) {
      alert(ALERT_MESSAGE_COMMON_ERROR);
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    }
    // ローディングを解除
    this.$store.commit("setLoading", false);
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  /**
   * mounted ライフサイクルフック
   */
  mounted: async function() {
    // メソッド名を定義
    const method_name="function"
    // オペレーションタイプを定義
    const operation_type="0007"

    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
    // バリデーションエラーをリセット
    this.$refs.form.resetValidation();
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  /**
   * beforeRouteLeave ライフサイクルフック
   */
  async beforeRouteLeave(to, from, next) {
    // メソッド名を定義
    const method_name="beforeRouteLeave"
    // オペレーションタイプを定義
    const operation_type="0008"

    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

    // 未保存チェックを実施し、未保存の場合はエラーを表示
    if (!this.isSavedData()) {
      if (confirm(CONFIRM_MESSAGE_UNSAVED_DATA)) {
        // バリデーションチェックでエラーの際は登録しない
        if (!this.validateCheck()) {
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
        // ローディング画面を表示
        this.$store.commit("setLoading", true);
        // 修正：入力データで更新
        if (!(await this.updateTables())) {
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
        // ローディングを解除
        this.$store.commit("setLoading", false);
      }
    }
    next();
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  methods: {
    /**
     * ログインユーザー情報の取得処理
     * ・ログインユーザーコードの取得
     * ・ログインユーザーの所属部門の取得
     */
    async getLoginUserInfo() {
      // メソッド名を定義
      const method_name="getLoginUserInfo"
      // オペレーションタイプを定義
      const operation_type="0001"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ログイン中のユーザー情報を取得
      this.loginUserInfo = this.$store.getters.user;

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return (this.loginUserInfo !== null);
    },
    /**
     * 初期化処理
     * ・テキストボックスのデータ取得
     */
    async initialize() {
      // メソッド名を定義
      const method_name="initialize"
      // オペレーションタイプを定義
      const operation_type="0001"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      // テキストボックス用リストの取得
      // 検索条件 ※${部門コード}はログインユーザの所属部門を取得し設定すること。
      let condition = {
        where_options: "AND BUMON_CD = " + this.loginUserInfo.BUMON_CD,
      };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(listM_SHOKISETTEI, condition)
        );

        // 結果判定
        if (result.data.listM_SHOKISETTEI) {
          // データを取得できた場合
          // 取得結果をリストに追加
          let resultData = result.data.listM_SHOKISETTEI;

          for (const data of resultData) {
            this.currentParams = {
              departmentCode: data.BUMON_CD,
              departmentName: data.BUMON_NAME,
              postalCode: data.YUBIN_NO,
              address: data.JUSHO_NAME,
              telNo: data.DENWA_NO,
              faxNo: data.FAX_NO,
              departmentOrderNo: data.HACCHU_NO,
              toshinRate: data.TOSHIN_KAKERITSU_SU,
              authorizerMail: data.SHONINSHA_EMAIL,
              updatedDate: data.UPDATE_DATE,
            };
          }
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query:"listM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
        } else {
          // データを取得できなかった場合
          // @TODO データ取得無しの場合の処理を以下に記載。
          // ログ出力(SQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3010"], query: "listM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        console.log("異常系ログ:" + JSON.stringify(error));
        // ローディング画面の解除
        this.$store.commit("setLoading", false);
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "listM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }

      // 入力前のデータを保存
      this.saveOriginalParams();
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 更新ボタン押下時
     */
    async onClickUpdate() {
      // メソッド名を定義
      const method_name="onClickUpdate"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // バリデーションチェックでエラーの際は登録しない
      if (!this.validateCheck()) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // 保存済みデータの場合、ダイアログを表示
      if (this.isSavedData()) {
        alert(ALERT_ALREADY_SAVED_DATA);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // ローディング画面の表示
      this.$store.commit("setLoading", true);
      // 新規登録：入力データを登録
      if (!(await this.updateTables())) {
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // エラーダイアログを表示
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // ローディング画面を解除(仮で解除を1500ミリ遅延)
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 入力データの保存
     */
    async updateTables() {
      // メソッド名を定義
      const method_name="updateTables"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // DBに入力データ登録
      let sqlList = [
        "UPDATE M_SHOKISETTEI SET " +
          "BUMON_CD='" +
          this.currentParams.departmentCode +
          "', " +
          "BUMON_NAME='" +
          this.currentParams.departmentName +
          "', " +
          "YUBIN_NO='" +
          this.currentParams.postalCode +
          "', " +
          "JUSHO_NAME='" +
          this.currentParams.address +
          "', " +
          "DENWA_NO='" +
          this.currentParams.telNo +
          "', " +
          "FAX_NO='" +
          this.currentParams.faxNo +
          "', " +
          "HACCHU_NO='" +
          this.currentParams.departmentOrderNo +
          "', " +
          "TOSHIN_KAKERITSU_SU='" +
          this.currentParams.toshinRate +
          "', " +
          "SHONINSHA_EMAIL='" +
          this.currentParams.authorizerMail +
          "', " +
          "UPDATE_USER_NAME='" +
          this.loginUserInfo.LOGIN_USER_CD +
          "', " +
          "UPDATE_DATE=CURDATE() " +
          "WHERE BUMON_CD='" +
          this.currentParams.departmentCode +
          "' ",
        "SELECT * FROM M_SHOKISETTEI WHERE BUMON_CD='" +
          this.currentParams.departmentCode +
          "' ",
      ];
      let condition = { SQLs: sqlList };
      console.info({ condition });
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(executeTransactSql, condition)
        );
        // レスポンスデータを取得できた際の処理
        if (result) {
          const responseBody = JSON.parse(result.data.executeTransactSql.body);

          // SQL実行でエラーが発生した場合の処理
          if (result.data.executeTransactSql.statusCode > 200) {
            console.info({ responseBody });
            // レスポンスメッセージ
            let message = responseBody.message;
            console.error({ message });
            // エラー内容
            const errorMessage = responseBody.error;
            console.error({ errorMessage });
            // ログ出力(Lamda関数でのSQL実行でステータスエラー)
            addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          } else {
            // SQLが正常に終了した際の処理
            if (responseBody.data) {
              // SELECT文の結果はresponseBody.dataとして返却される
              // 複数SELECT文を投げた場合responseBody.data[0]、responseBody.data[1]と配列で返却される
              for (const rows of responseBody.data) {
                console.dir(rows, { depth: null });
              }
              // DBから取得した値を設定
              let resultData = responseBody.data[0][0];
              this.currentParams = {
                departmentCode: resultData.BUMON_CD,
                departmentName: resultData.BUMON_NAME,
                postalCode: resultData.YUBIN_NO,
                address: resultData.JUSHO_NAME,
                telNo: resultData.DENWA_NO,
                faxNo: resultData.FAX_NO,
                departmentOrderNo: resultData.HACCHU_NO,
                toshinRate: resultData.TOSHIN_KAKERITSU_SU,
                authorizerMail: resultData.SHONINSHA_EMAIL,
                updatedDate: resultData.UPDATE_DATE,
              };
              // ログ出力(SQL-end_OK)
              addLog('INFO', {message: MSG.INFO["1004"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            }
          }
          console.info(JSON.parse(result.data.executeTransactSql.body));
        } else {
          // レスポンスデータを取得できなかった際の処理
          console.log("updateTables : " + JSON.stringify(result));
          // ログ出力(Lamda関数でのSQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        console.log("updateTables : 異常終了 = " + JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }

      // 未保存チェック用配列を更新
      this.saveOriginalParams();
      alert(ALERT_COMPLETE_CRUD_DATA);

      // 最新の登録データで画面を更新
      this.initialize();

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 未保存チェック用比較データの更新
     */
    saveOriginalParams() {
      // メソッド名を定義
      const method_name="saveOriginalParams"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 保存時の入力データを未保存チェック時の比較データとして更新
      this.originalParams = JSON.parse(JSON.stringify(this.currentParams));

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 未保存チェック
     */
    isSavedData() {
      // メソッド名を定義
      const method_name="isSavedData"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);

      return (
        JSON.stringify(this.currentParams) ==
        JSON.stringify(this.originalParams)
      );
    },
    /**
     * バリデーションチェック
     */
    validateCheck() {
      // メソッド名を定義
      const method_name="validateCheck"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // バリデーションチェックでエラーの際は登録しない
      const form = this.$refs.form;
      if (!form.validate()) {
        // 最初のエラー箇所へスクロール
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return false;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
  },
};
</script>
